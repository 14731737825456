import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { formatDate } from '../components/common';
import BioList from '../components/bio-list';
import Media from '../components/media';

export const query = graphql`
	query ArticleTemplate($id: ID!) {
		cms {
			Article(where: { id: $id }) {
				title
				content
				posted
				tags {
					name
				}
				authors {
					name
					slug
					image {
						publicUrl
						gatsbyFile {
							childImageSharp {
								fluid(maxWidth: 700, maxHeight: 700) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
				image {
					publicUrl
					gatsbyFile {
						childImageSharp {
							fluid(maxWidth: 700, maxHeight: 700) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	}
`;

export default ({ data }) => (
	<Layout className='bg-article' title={data.cms.Article.title}>
		<SEO
			title={data.cms.Article.title}
			keywords={['article', ...data.cms.Article.tags.map(({ name }) => name)]}
			description={`IEEE SSCS AlexSC Article on ${formatDate(data.cms.Article.posted)}`}
			image={data.cms.Article.image ? data.cms.Article.image.publicUrl : undefined}
			type='article'
			meta={[
				{
					property: 'article:published_time',
					content: data.cms.Article.posted
				},
				...data.cms.Article.tags.map(({ name }) => ({
					property: 'article:tag',
					content: name
				}))
				// TODO: Add authors profile pages.
			]}
		/>
		<h1>{data.cms.Article.title}</h1>
		<BioList list={data.cms.Article.authors} />
		{data.cms.Article.image ? (
			<Img fluid={data.cms.Article.image.gatsbyFile.childImageSharp.fluid} className='course-image' />
		) : null}
		<section className='paper p-4 rich-text'>
			<Media content={data.cms.Article.content} />
		</section>
	</Layout>
);
